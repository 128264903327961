@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');

.App {

}

.Uploader {
  margin: 10px;
  padding: 10px;
  border: solid 1px black;
}

.Header {
  background-color: #0c0000;
  margin: 0;
  padding: 10px;
}

.Header h1 {
  color: #ffffff;
}


body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(12, 0, 0, 0);
}

.navbar {
  padding: 0 0 0 10px;
}


.wavesurfer-handle-start {
  width: 2px !important;
  cursor: col-resize !important;
}
.wavesurfer-handle-end {
  width: 2px !important;
  cursor: col-resize !important;
}

/*--bs-btn-active-color: #fff;*/
/*// --bs-btn-active-bg: #343a40;*/



.btn-outline-dark {
  --bs-btn-color: #343a40;
  --bs-btn-border-color: #343a40;
  --bs-btn-hover-color: #343a40;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #343a40;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #343a40;
  --bs-btn-active-border-color: #343a40;
  --bs-btn-active-shadow: inset 0 3px 5px rgb(255, 255, 255);
  --bs-btn-disabled-color: #343a40;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #343a40;
  --bs-gradient: none;
}